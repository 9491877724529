/* Usage Legend Styles */
.legend-package {
  font-weight: normal;
  margin-right: 1rem;
  font-size: 80%;
  color: #00599d;
}

.legend-cost {
  font-weight: normal;
  margin-right: 1rem;
  color: #512DA8;
  font-size: 80%;
}

.legend-table {
  font-weight: normal;
  margin-right: 1rem;
  color: #006617;
  font-size: 80%;
}


.legend-formula {
  font-weight: normal;
  margin-right: 1rem;
  color: #E64A19;
  font-size: 80%;
}

.legend-population {
  font-weight: normal;
  margin-right: 1rem;
  color: #C2185B;
  font-size: 80%;
}

.legend-next-step {
  font-weight: normal;
  margin-right: 1rem;
  color: #5D4037;
  font-size: 80%;
}

.legend-package:before {
  content: "\25CF \A0";
}

.legend-cost:before {
  content: "\25CF \A0";
}

.legend-table:before {
  content: "\25CF \A0";
}

.legend-formula:before {
  content: "\25CF \A0";
}

.legend-population:before {
  content: "\25CF \A0";
}

.legend-next-step:before {
  content: "\25CF \A0";
}
