@use '@angular/material' as mat;

// $custom-typography: mat.define-typography-config($font-family: 'Source Sans Pro, sans-serif',
//   $button: mat.define-typography-level(14px, 20px, 400, $letter-spacing: normal),
//   $caption: mat.define-typography-level(14px, 20px, 400, $letter-spacing: normal),
//   $body-1: mat.define-typography-level(14px, 20px, 400, $letter-spacing: normal),
//   $headline-1: mat.define-typography-level(14px, 20px, 400, $letter-spacing: normal),
//   $body-2: mat.define-typography-level(14px, 20px, 400, $letter-spacing: normal));


$custom-typography: mat.m2-define-typography-config($font-family: 'Source Sans Pro, sans-serif',
    $button: mat.m2-define-typography-level($font-family: 'Source Sans Pro, sans-serif',
      $font-weight: 400,
      $font-size: 1rem,
      $line-height: 1.5rem),
    $body-1: mat.m2-define-typography-level($font-family: 'Source Sans Pro, sans-serif',
      $font-weight: 400,
      $font-size: 1rem,
      $line-height: 1.5rem),
    $body-2: mat.m2-define-typography-level($font-family: 'Source Sans Pro, sans-serif', $font-weight: 400,
      $font-size: 1rem,
      $line-height: 1.5rem),
    $headline-5: mat.m2-define-typography-level($font-family: 'Source Sans Pro, sans-serif', $font-weight: 500,
      $font-size: 1.8rem,
      $line-height: 1.25rem),
    $headline-6: mat.m2-define-typography-level($font-family: 'Source Sans Pro, sans-serif', $font-size: 1.4rem,
      $line-height: 1.25rem),
  );


// Define the default theme (same as the example above).
$admin-app-primary: mat.m2-define-palette(mat.$m2-indigo-palette);
$admin-app-accent: mat.m2-define-palette(mat.$m2-blue-palette, A700);
$admin-app-warn: mat.m2-define-palette(mat.$m2-red-palette, 700);

$admin-app-theme: mat.m2-define-light-theme((color: (primary: $admin-app-primary,
        accent: $admin-app-accent,
        warn: $admin-app-warn,
      ),
      typography: $custom-typography,
    ));


@include mat.all-component-typographies($custom-typography);
@include mat.core();
@include mat.strong-focus-indicators();


// Define the default theme (same as the example above).
// $npc-app-primary: mat.define-palette(mat.$indigo-palette);
// $npc-app-accent: mat.define-palette(mat.$blue-palette, A200, A100, A400);
// $npc-app-warn: mat.define-palette(mat.$red-palette, 900);
// $npc-app-theme: mat.define-light-theme((color: (primary: $npc-app-primary,
//         accent: $npc-app-accent,
//         warn: $npc-app-warn ),
//       typography: $custom-typography));

// Include the default theme styles.
@include mat.all-component-themes($admin-app-theme);
@include mat.strong-focus-indicators-theme($admin-app-theme);





/* Hack for Material Color Picker to remove bootstrap box-sizing */
.mcc-color-picker-selector-preview {
  box-sizing: unset;
}

/* Matieral Overrides */
// .mat-mdc-option {
//   line-height: 28px !important;
//   height: 28px !important;
//   font-size: 14px !important;
// }

// .mat-mdc-optgroup-label {
//   line-height: 32px !important;
//   height: 32px !important;
// }

.mat-mdc-tab-header {
  margin-bottom: 1em;
}